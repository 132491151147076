import React, { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import Identicon from 'react-identicons';
import BigNumber from 'bignumber.js'
import { ArrowUpRight } from 'react-feather'
import { CardBody, Button, Text, Heading } from '@pancakeswap-libs/uikit'
import { Wrapper, BottomGrouping } from 'components/swap/styleds'
import { AutoColumn } from 'components/Column'
import { AutoRow } from 'components/Row'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useActiveWeb3React } from 'hooks'
import { useTokenContract, useLotteryContract } from 'hooks/useContract'
import { useSingleContractMultipleData } from 'state/multicall/hooks'
import LOTTERY_ABI from 'constants/abis/lottery.json'
import LOTTERY_CONFIG from 'constants/lottery'
import { getBscScanLink } from 'utils'
import ApproveTokenModal from './components/ApproveModal'
import PlayLotteryModal from './components/PlayLotteryModal'
import ConfirmationModal from '../Farm/components/ConfirmationModal'
import AppBody, { AppBodyFlexible } from '../AppBody'
import Countdown from './components/Countdown'
import type { LotteryType, LotteryIdType, TicketType } from './type'

BigNumber.config({ EXPONENTIAL_AT: 1e+9 }) // never return exponential values

const Lottery: React.FC = () => {
    const { account, chainId } = useActiveWeb3React()
    const accountOrZero = account || '0x0000000000000000000000000000000000000000'

    const toAbsoluteFormat = (value: BigNumber | number | string, decimals = 18) => {
        return new BigNumber(value || 0).dividedBy(new BigNumber(10).pow(decimals)).toString();
    }
    
    const [newTicketEvents, setNewTicketEvents] = useState<any[]>([])
    const [pendingTxHash, setPendingTxHash] = useState<string>('')
    const [showApprovalModal, setShowApprovalModal] = useState<boolean>(false)
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
    const [showPlayLotteryModal, setShowPlayLotteryModal] = useState<boolean>(false)
    const lotteryContract = useLotteryContract(LOTTERY_CONFIG.lotteryContractAddressV2, true)
    const tokenContract = useTokenContract(LOTTERY_CONFIG.tokenAddress, true)
    
    const fetchTicketEvent = async () => {
        try {
            const newProvider = new ethers.providers.JsonRpcProvider("https://bsc.getblock.io/d1ae3cc5-3ab4-478e-8578-11f3fc02d55b/mainnet/");
            const newLotteryContract = new ethers.Contract(LOTTERY_CONFIG.lotteryContractAddressV2, LOTTERY_ABI, newProvider);
            const eventFilter = newLotteryContract.filters.NewTicket()
            let _blockTrackers = await newLotteryContract.getBlockTracker()
            _blockTrackers = _blockTrackers.map((block: any) => block.toNumber())
            
            const _ticketEvents: any[] = [];

            if (_blockTrackers && _blockTrackers.length > 0) {
                for (let i = 0; i < _blockTrackers.length; i++) {
                    const _block = _blockTrackers[i]
                    const _ticketEventLogs = await newLotteryContract.queryFilter(eventFilter, _block, _block + 1)
                    const _ticketEvent = _ticketEventLogs[0]
                    const { transactionHash } = _ticketEvent
                    const { player, lotteryId, amount } = _ticketEvent?.args as any
                    
                    _ticketEvents.push({
                        player, 
                        lotteryId: lotteryId?.toNumber(), 
                        amount: toAbsoluteFormat(amount?.toString(), LOTTERY_CONFIG.tokenDecimals),
                        transactionHash
                    })
                }
            }
            
            setNewTicketEvents(_ticketEvents)
        } catch (e) {
            
            console.error('fetchTicketEvent', e)
        }
    }

    useEffect(() => {
        if (lotteryContract) {
            fetchTicketEvent()
        }
        
        // eslint-disable-next-line
    }, [lotteryContract])


    let accountTokenBalance = '0';
    let accountTokenAllowance = '0';
    let lotteryIsActive = false;
    let currentLotteryId: LotteryIdType = 1;
    let currentLotteryData: LotteryType = {
        startTime: 0,
        endTime: 0,
        tax: 0,
        minTicketAmount: '0',
        totalCollected: '0',
    };
    let accountTicket: TicketType = {
        amount: '0',
        lotteryId: 0,
    };
    const accountTokenBalanceCall = useSingleContractMultipleData(
        tokenContract,
        'balanceOf',
        [[accountOrZero]]
    )
    
    const accountTokenAllowanceCall = useSingleContractMultipleData(
        tokenContract,
        'allowance',
        [[accountOrZero, LOTTERY_CONFIG.lotteryContractAddressV2]]
    )

    const lotteryIsActiveCall = useSingleContractMultipleData(
        lotteryContract,
        'lotteryIsActive',
        [[]]
    )

    const currentLotteryIdCall = useSingleContractMultipleData(
        lotteryContract,
        'lotteryId',
        [[]]
    )

    const currentLotteryDataCall = useSingleContractMultipleData(
        lotteryContract,
        'currentLottory',
        [[]]
    )
    
    const accountTicketCall = useSingleContractMultipleData(
        lotteryContract,
        'getTicket',
        [[accountOrZero]]
    )

    if (accountTokenBalanceCall[0]?.result) {
        const result = accountTokenBalanceCall[0]?.result[0].toString()
        accountTokenBalance = toAbsoluteFormat(result, LOTTERY_CONFIG.tokenDecimals)
    }

    if (accountTokenAllowanceCall[0]?.result) {
        const result = accountTokenAllowanceCall[0]?.result[0].toString()
        accountTokenAllowance = toAbsoluteFormat(result, LOTTERY_CONFIG.tokenDecimals)
    }

    if (lotteryIsActiveCall[0]?.result !== undefined) {
        lotteryIsActive = lotteryIsActiveCall[0]?.result[0]
    }

    if (currentLotteryIdCall[0]?.result !== undefined) {
        currentLotteryId = currentLotteryIdCall[0]?.result[0].toNumber()
    }

    if (currentLotteryDataCall[0]?.result) {
        const result = currentLotteryDataCall[0]?.result[0]
    
        currentLotteryData = {
            /* eslint-disable */
            startTime: result['startTime'].toNumber(),
            endTime: result['endTime'].toNumber(),
            tax: result['tax'],
            minTicketAmount: toAbsoluteFormat(result['minTicketAmount'].toString(), LOTTERY_CONFIG.tokenDecimals),
            totalCollected: toAbsoluteFormat(result['totalCollected'].toString(), LOTTERY_CONFIG.tokenDecimals),
        }
    }

    if (accountTicketCall[0]?.result) {
        const result = accountTicketCall[0]?.result[0]
        accountTicket = {
            amount: toAbsoluteFormat(result['amount'].toString(), LOTTERY_CONFIG.tokenDecimals),
            lotteryId: result['lotteryId'].toNumber(),
        }
    }
    
    const playLottery = async (amount: string) => {
        try {
            if (!lotteryContract) {
                return
            }
            setPendingTxHash('')
            setShowPlayLotteryModal(false)
            setShowConfirmModal(true)
            
            const exponential = new BigNumber(10).exponentiatedBy(LOTTERY_CONFIG.tokenDecimals)
            const bigAmount = new BigNumber(amount).multipliedBy(exponential)
            const tx = await lotteryContract.play(bigAmount.toString())
            setPendingTxHash(tx.hash);
            await tx.wait()
        } catch (e) {
            console.error(e)
        } finally {
            setShowConfirmModal(false)
            setPendingTxHash('')
        }
    }

    const withdrawLotteryTicket = async () => {
        try {
            if (!lotteryContract) {
                return
            }
            setPendingTxHash('')
            setShowConfirmModal(true)
            const tx = await lotteryContract.withdrawTicket()
            setPendingTxHash(tx.hash);
            await tx.wait()
        } catch (e) {
            console.error(e)
        } finally {
            setShowConfirmModal(false)
            setPendingTxHash('')
        }
    }

    const transferLotteryTicket = async () => {
        try {
            if (!lotteryContract) {
                return
            }
            setPendingTxHash('')
            setShowConfirmModal(true)
            const tx = await lotteryContract.transferTicket()
            setPendingTxHash(tx.hash);
            await tx.wait()
        } catch (e) {
            console.error(e)
        } finally {
            setShowConfirmModal(false)
            setPendingTxHash('')
        }
    }

    const approveCallback = async (amount: string) => {
        try {
            if (!tokenContract) {
                return
            }

            setPendingTxHash('')
            setShowApprovalModal(false)
            setShowConfirmModal(true)
            
            const exponential = new BigNumber(10).exponentiatedBy(LOTTERY_CONFIG.tokenDecimals)
            const bigAmount = new BigNumber(amount).multipliedBy(exponential)
            const tx = await tokenContract.approve(LOTTERY_CONFIG.lotteryContractAddressV2, bigAmount.toString())
            setPendingTxHash(tx.hash);
            await tx.wait()
        } catch (e) {
            console.error(e)
        } finally {
            setShowConfirmModal(false)
            setPendingTxHash('')
        }
    }

    return (
        <>
            <PlayLotteryModal
                isOpen={showPlayLotteryModal}
                onDismiss={() => setShowPlayLotteryModal(false)}
                tokenSymbol={LOTTERY_CONFIG.tokenSymbol}
                tokenBalance={accountTokenBalance}
                tokenAllowance={accountTokenAllowance}
                playLottery={playLottery}
            />
            <ApproveTokenModal 
                isOpen={showApprovalModal} 
                onDismiss={() => setShowApprovalModal(false)}
                tokenBalance={accountTokenBalance}
                approvalCallback={approveCallback}
                tokenSymbol={LOTTERY_CONFIG.tokenSymbol}
             />
            <ConfirmationModal
                isOpen={showConfirmModal}
                onDismiss={() => setShowConfirmModal(false)}
                hash={pendingTxHash}
                content={() => (
                    <div style={{ padding: '2rem', textAlign: 'center' }}>
                        <Text fontSize="20px">Confirm this transaction in your wallet</Text>
                    </div>
                )}
                attemptingTxn={!pendingTxHash}
                pendingText="Pending Confirmation"
            />
            {/* Current Lottery Info */}
            <AppBodyFlexible>
                <Wrapper>              
                    <CardBody>
                        <AutoRow justify="space-between" my="10px">
                            <AutoColumn>
                                <Heading size="lg">
                                    Current LifeSpring (Giveaway) Round ({currentLotteryId})
                                </Heading>
                            </AutoColumn>
                        </AutoRow>
                        <hr />

                        <AutoRow justify="space-between" my="10px">
                            <AutoColumn>
                                <Text fontSize="18px" bold>Total ticket</Text>
                                <Text fontSize="16px">
                                    {currentLotteryData.totalCollected} {LOTTERY_CONFIG.tokenSymbol}
                                </Text>
                            </AutoColumn>
                            <AutoColumn>
                                <Text fontSize="18px" bold>Min ticket</Text>
                                <Text fontSize="16px">
                                    {currentLotteryData.minTicketAmount} {LOTTERY_CONFIG.tokenSymbol}
                                </Text>
                            </AutoColumn>

                            <AutoColumn>
                                <Text fontSize="18px" bold>Tax</Text>
                                <Text fontSize="16px">
                                   {currentLotteryData.tax}%
                                </Text>
                            </AutoColumn>

                            <AutoColumn>
                                <Text fontSize="18px" bold>Status</Text>
                                <Text fontSize="16px">
                                    { 
                                        lotteryIsActive ?
                                        <Countdown epochSeconds={currentLotteryData.endTime} />
                                        :
                                        "Inactive" 
                                    }
                                </Text>
                            </AutoColumn>              
                        </AutoRow>
                    </CardBody>
                </Wrapper>
            </AppBodyFlexible>

            {/* Wallet info and Tickets */}
            <AutoRow align="top" justify="center" gap="md" my="10px">
                <AutoColumn style={{minWidth: "350px", margin: "30px"}}>
                    <AppBody>
                        <Wrapper>              
                            <CardBody>
                               <Heading>Buy ticket with Balance</Heading>
                               <hr />

                               <AutoColumn justify='center'>
                                    <img
                                        src={LOTTERY_CONFIG.tokenLogo}
                                        alt=""
                                        style={{ width: '50px', height: '50px', margin: '10px'}}
                                    />
                                    <Text fontSize="18px" bold>
                                        {accountTokenBalance}
                                    </Text>
                                    <Text fontSize="14px" bold style={{margin: "10px"}}>
                                        {LOTTERY_CONFIG.tokenSymbol}
                                    </Text>
                                </AutoColumn>
                                {
                                    account ? 
                                    <BottomGrouping>
                                        <Button 
                                            variant="primary" 
                                            mt="10px"
                                            style={{ width: '100%' }}
                                            disabled={
                                                pendingTxHash !== '' ||
                                                accountTokenBalance === '0' ||
                                                showApprovalModal
                                            }
                                            onClick={
                                                () => setShowApprovalModal(true)
                                            }
                                            >
                                            Approve some
                                        </Button>
                                        <Button 
                                            variant="primary" 
                                            title="Transfer your ticket to current lottery round"
                                            mt="10px"
                                            style={{ width: '100%' }}
                                            disabled={
                                                pendingTxHash !== '' ||
                                                accountTokenBalance === '0' ||
                                                showApprovalModal
                                            }
                                            onClick={
                                                () => approveCallback(accountTokenBalance)
                                            }
                                            >
                                            Approve all
                                        </Button>
                                    </BottomGrouping>
                                    :
                                    <AutoColumn justify='center'>
                                        <ConnectWalletButton 
                                            style={{ width: '100%' }}
                                        />
                                    </AutoColumn>
                                }
                                
                            </CardBody>
                        </Wrapper>
                    </AppBody>
                </AutoColumn>   

                <AutoColumn style={{minWidth: "350px", margin: "30px"}}>
                    <AppBody>
                        <Wrapper>              
                            <CardBody>
                               <Heading>Deposit into balance to join LifeSpring</Heading>
                               <hr />

                               <AutoColumn justify='center'>
                                    <img
                                        src={LOTTERY_CONFIG.tokenLogo}
                                        alt=""
                                        style={{ width: '50px', height: '50px', margin: '10px'}}
                                    />
                                    <Text fontSize="18px" bold>
                                        {accountTicket.amount}
                                    </Text>
                                    <Text fontSize="14px" bold style={{margin: "10px"}}>
                                        {LOTTERY_CONFIG.tokenSymbol}
                                    </Text>
                                </AutoColumn>
                                {
                                    account ? 
                                    <AutoColumn justify='center'>
                                        <Button 
                                            variant="primary" 
                                            mt="10px"
                                            style={{ width: '75%' }}
                                            disabled={
                                                !lotteryIsActive ||
                                                accountTicket.lotteryId === currentLotteryId
                                            }
                                            onClick={
                                                () => setShowPlayLotteryModal(true)
                                            }
                                            >
                                            Join LifeSpring (Giveaway)
                                        </Button>
                                        <Button 
                                            variant="primary" 
                                            title="Transfer your ticket to current lottery round"
                                            mt="10px"
                                            style={{ width: '75%' }}
                                            disabled={
                                                accountTicket.amount === '0' ||
                                                !lotteryIsActive ||
                                                accountTicket.lotteryId === currentLotteryId
                                            }
                                            onClick={
                                                () => transferLotteryTicket()
                                            }
                                            >
                                            Transfer Ticket
                                        </Button>

                                        <Button 
                                            variant="primary" 
                                            title="Withdraw ticket when lottery is inactive"
                                            mt="10px"
                                            style={{ width: '75%' }}
                                            disabled={
                                                accountTicket.amount === '0' ||
                                                (
                                                    lotteryIsActive &&
                                                    accountTicket.lotteryId === currentLotteryId
                                                )
                                            }
                                            onClick={
                                                () => withdrawLotteryTicket()
                                            }
                                            >
                                            Withdraw Ticket
                                        </Button>
                                    </AutoColumn>
                                    :
                                    <AutoColumn justify='center'>
                                        <ConnectWalletButton 
                                            style={{ width: '100%' }}
                                        />
                                    </AutoColumn>
                                }
                                
                            </CardBody>
                        </Wrapper>
                    </AppBody>
                </AutoColumn> 
            </AutoRow>

            {/* Recent Play transactions */}
            <AutoRow align="top" justify="center" gap="md" my="10px">
                <AutoColumn style={{minWidth: "350px", margin: "10px"}}>
                    <AppBody>
                        <Wrapper>              
                            <CardBody>
                                <Heading>Recent Play transactions</Heading>
                                <hr />
                                <AutoRow mb="10px" align='center' justify='space-between'>
                                    <Text fontSize="16px" bold>
                                        Player
                                    </Text>
                                    <Text fontSize="16px" bold>
                                        Amount
                                    </Text>
                                    <Text fontSize="16px" bold>
                                        Tx
                                    </Text>
                                </AutoRow>
                                {
                                    newTicketEvents.length > 0 ?
                                    newTicketEvents.map( (ticket, index) => (
                                        <AutoRow my="5px" align='center' justify='space-between' key={index}>
                                            
                                            <Text fontSize="16px">
                                                <Identicon
                                                    string={ticket.player}
                                                    size={16}
                                                /> &nbsp;
                                                {ticket.player.slice(0, 4)}...{ticket.player.slice(-2)}
                                            </Text>
                                            <Text fontSize="16px">
                                                {ticket.amount}
                                            </Text>
                                        
                                            <a href={getBscScanLink(chainId as any, ticket.transactionHash, 'transaction')} rel='noreferrer' target='_blank'>
                                                <ArrowUpRight size={16} />
                                            </a>
                                        </AutoRow>
                                    ))
                                    :
                                    (
                                        <AutoRow justify='center'>
                                            <Text fontSize="16px" bold>
                                                No data
                                            </Text>
                                        </AutoRow>
                                    )
                                }
                            </CardBody>
                        </Wrapper>
                    </AppBody>
                </AutoColumn>
                <AutoColumn style={{minWidth: "350px", margin: "10px"}}>
                    <AppBody>
                        <Wrapper>              
                            <CardBody>
                                <Heading>Recent Winners</Heading>
                                <hr />
                                
                                <AutoRow mb="10px" align='center' justify='space-between'>
                                    <Text fontSize="16px" bold>
                                        Player
                                    </Text>
                                    <Text fontSize="16px" bold>
                                        Tx
                                    </Text>
                                </AutoRow>
                                <RecentWinners
                                    lotteryRound={lotteryIsActive ? currentLotteryId - 1 : currentLotteryId}
                                    lotteryContract={lotteryContract}
                                    chainId={chainId}
                                />
                                 
                            </CardBody>
                        </Wrapper>
                    </AppBody>
                </AutoColumn>
            </AutoRow>
        </>
    )
}


function RecentWinners({lotteryRound, lotteryContract, chainId}: {lotteryRound?: number, lotteryContract: any, chainId?: number}) {
    let previousLotteryWinner: Array<string> = [];

    const previousLotteryWinnerCall = useSingleContractMultipleData(
        lotteryContract,
        'getWinners',
        [
            [lotteryRound],
            // [currentLotteryId > 1 ? currentLotteryId - 1 : 1],
            // [currentLotteryId > 2 ? currentLotteryId - 2 : 2],
        ]
    )

    if (previousLotteryWinnerCall.length > 0) {
        previousLotteryWinner = previousLotteryWinnerCall.slice(0, 3).reverse().map(winner => winner.result? winner.result[0] : '')
        previousLotteryWinner = previousLotteryWinner.filter(Boolean).flat()
    }

    // Project owner is broke, need to do hardcoded winners
    const temporaryWinners = [
        '0x6bc7e56e8b42b0D754ceF1fe65066b4198d3B149',
        '0x1A9E5911d3bE64122e3C672E0a24B022E0041252',
        '0xb5907eE77532Fa82753B5b8364315a57aD9597F9'
    ]

    return (
        <>
            {
                // previousLotteryWinner.length &&
                // previousLotteryWinner.length > 0 ?
                // previousLotteryWinner.map( (winner, index) => (
                temporaryWinners.length > 0 ?
                temporaryWinners.map( (winner, index) => (
                    <AutoRow my="5px" align='center' justify='space-between' key={index}>
                        <Identicon
                            string={winner}
                            size={16}
                        />
                        <Text fontSize="16px">
                            {winner.slice(0, 16)}...{winner.slice(-4)}
                        </Text>
                        <a href={getBscScanLink(chainId as any, winner, 'address')} rel='noreferrer' target='_blank'>
                            <ArrowUpRight size={16} />
                        </a>
                    </AutoRow>
                ))
                :
                (
                    <AutoRow justify='center'>
                        <Text fontSize="16px" bold>
                            No data
                        </Text>
                    </AutoRow>

                )
            }
        </>
    )
}

export default Lottery