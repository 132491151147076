export const AppInfoConfig = {
    name: 'EagonAlpha',
    website: 'https://eagonalpha.exchange/',
    logoUrl: '/logo.png',
    logoDarkUrl: '/logo_dark.png',
    logoLightUrl: '/logo_light.png',
    swapUrl: 'https://swap.eagonalpha.exchange/',
}

export const AppTokenConfig = {
    name: 'EagonAlpha',
    symbol: 'EALPHA',
    decimals: 18,
    BSC_CONTRACT_ADDRESS: '0x893aE1fDf21aD92E54FcB97F3A87F47f8Ecec2e6',
    projectLink: 'https://eagonalpha.exchange/',
    coinLogoUrl: '/logo.png',
}
export const BSC_ROUTER = '0x10ED43C718714eb63d5aA57B78B54704E256024E'
export const BSC_FACTORY = '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73'


export const socialChannels = [
    {
      label: "Telegram",
      icon: "TelegramIcon",
      items: [
        {
          label: "Telegram Chat",
          href: "https://t.me/EagonAlphaOfficial",
        },
      ],
    },
    {
      label: "Twitter",
      icon: "TwitterIcon",
      href: "https://twitter.com/EagonAlpha",
    },
    {
      label: "Medium",
      icon: "MediumIcon",
      href: "https://medium.com/@eagonalpha",
    },
  ];

export const customBaseColors = {
    failure: "#A42667",
    primary: "#6F36BF",
    primaryBright: "#7725E9",
    primaryDark: "#341773",
    secondary: "#7645D9",
    success: "#7641B2",
    warning: "#B47615",
};

export const customLightColors = {
    background: "#F1EDF3",
    backgroundDisabled: "#E9EAEB",
    contrast: "#191326",
    invertedContrast: "#FFFFFF",
    input: "#eeeaf4",
    inputSecondary: "#E0D4F3",
    tertiary: "#EFF4F5",
    text: "#3A2466",
    textDisabled: "#BDC2C4",
    textSubtle: "#8f80ba",
    borderColor: "#E4E1E5",
    card: "#FFFFFF",
    gradients: {
      bubblegum: "linear-gradient(139.73deg, #E6FDFF 0%, #F3EFFF 100%)",
    },
  };
  
  export const customDarkColors = {
    secondary: "#9A6AFF",
    background: "#100C18",
    backgroundDisabled: "#3c3742",
    contrast: "#FFFFFF",
    invertedContrast: "#191326",
    input: "#483f5a",
    inputSecondary: "#66578D",
    primaryDark: "#8041D9",
    tertiary: "#353547",
    text: "#C7B5F0",
    textDisabled: "#666171",
    textSubtle: "#A28BD4",
    borderColor: "#524B63",
    card: "#27262c",
    gradients: {
      bubblegum: "linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%)",
    },
  };
  

