
export default {
    tokenName: 'EALPHA',
    tokenAddress: '0x893aE1fDf21aD92E54FcB97F3A87F47f8Ecec2e6',
    tokenDecimals: 18,
    tokenSymbol: 'EALPHA',
    tokenLogo: '/images/coins/0x893aE1fDf21aD92E54FcB97F3A87F47f8Ecec2e6.svg',
    chainId: 56,
    lotteryContractAddressV1: '0x84917800D3C0A0832b60715e9De462B511F4FB50',
    lotteryContractAddressV2: '0x14461737Ab42c68f731a499D33DAC5bc10785771',
}