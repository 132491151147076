import React, { useState } from 'react'
import BigNumber from 'bignumber.js'
import { Button, Text, Input } from '@pancakeswap-libs/uikit'
import Modal from 'components/Modal'
// import { useActiveWeb3React } from 'hooks'
import { Wrapper, Section, ContentHeader } from './Helpers'
import type { PoolType } from '../type'

interface ApproveTokenModalProps {
    isOpen: boolean
    onDismiss: () => void
    pool: PoolType
    tokenBalance: string
    approvalCallback: (T: string) => void
}

const ApproveTokenModal = ({
  isOpen,
  onDismiss,
  pool,
  tokenBalance,
  approvalCallback
}: ApproveTokenModalProps) => {

  const [approvalAmount, setApprovalAmount] = useState(tokenBalance)
  // confirmation screen
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={150}>
        <Wrapper>
            <Section>
                <ContentHeader onDismiss={onDismiss}>Approve {pool.stakeTokenSymbol} for farming</ContentHeader>
                
                <Text bold fontSize="16px" color="textSubtle" mb="20px">
                    Approve {pool.stakeTokenSymbol} to be used by MasterChef to farm {pool.rewardTokenSymbol}.
                </Text>
                <Text bold fontSize="16px" color="textSubtle" mb="20px">
                    {pool.stakeTokenSymbol} Balance: {tokenBalance}
                </Text>
                <Input 
                    // placeholder={tokenBalance}
                    value={approvalAmount}
                    onChange={(e) => setApprovalAmount(e.target.value)}
                    style={{ marginBottom: '20px', marginTop: '20px', }} 
                />
                <Button
                    onClick={() => approvalCallback(approvalAmount)}
                    disabled={
                        !parseFloat(approvalAmount) ||
                        new BigNumber(approvalAmount).lte(new BigNumber(0)) || 
                        new BigNumber(approvalAmount).gt(tokenBalance)
                    }
                >Approve</Button>
            </Section>
        </Wrapper>
    </Modal>
  )
}

export default ApproveTokenModal
