
export const TIMESTAMPED_MASTER_CHEF_POOLS = [
    {
        pid: 0,
        name: 'EALPHA',
        image: '/images/farm/ealpha-ealpha.png',
        description: 'EALPHA yield EALPHA',
        apr: 78,
        depositFee: 0,
        withdrawFee: 1,
        rewardRate: 252,
        stakeToken: '0x893aE1fDf21aD92E54FcB97F3A87F47f8Ecec2e6',
        stakeTokenDecimals: 18,
        stakeTokenSymbol: 'EALPHA',
        rewardToken: '0x893aE1fDf21aD92E54FcB97F3A87F47f8Ecec2e6',
        rewardTokenDecimals: 18,
        rewardTokenSymbol: 'EALPHA',
        chainId: 56,
    },
    /* {
        pid: 1,
        name: 'BNB-EALPHA LP',
        image: '/images/farm/bnb-ealpha.png',
        description: 'BNB-EALPHA LP yield EALPHA',
        apr: 198,
        depositFee: 0,
        withdrawFee: 1,
        rewardRate: 638,
        stakeToken: '0x2d61ab4dd36E6527657AcAbEa9CA9F8c66938522',
        stakeTokenDecimals: 18,
        stakeTokenSymbol: 'BNB-EALPHA',
        rewardToken: '0x893aE1fDf21aD92E54FcB97F3A87F47f8Ecec2e6',
        rewardTokenDecimals: 18,
        rewardTokenSymbol: 'EALPHA',
        chainId: 56,
    }, */
    {
        pid: 2,
        name: 'BNB-EALPHA LP',
        image: '/images/farm/bnb-ealpha.png',
        description: 'BNB-EALPHA LP yield EALPHA',
        apr: 198,
        depositFee: 0,
        withdrawFee: 1,
        rewardRate: 638,
        stakeToken: '0xE632EeE75adAdd97889bDCCDa7b0E770EaA6bBe7',
        stakeTokenDecimals: 18,
        stakeTokenSymbol: 'BNB-EALPHA',
        rewardToken: '0x893aE1fDf21aD92E54FcB97F3A87F47f8Ecec2e6',
        rewardTokenDecimals: 18,
        rewardTokenSymbol: 'EALPHA',
        chainId: 56,
    },

]

export const LOTTERY_SETTINGS = {

}