import React, { useState } from 'react'
import BigNumber from 'bignumber.js'
import { Button, Text, Input } from '@pancakeswap-libs/uikit'
import Modal from 'components/Modal'
// import { useActiveWeb3React } from 'hooks'
import { Wrapper, Section, ContentHeader } from '../../Farm/components/Helpers'

interface PlayLotteryProps {
    isOpen: boolean
    onDismiss: () => void
    tokenSymbol: string
    tokenBalance: string
    tokenAllowance: string
    playLottery: (T: string) => void
}

const PlayLotteryModal = ({
  isOpen,
  onDismiss,
  tokenSymbol,
  tokenBalance,
  tokenAllowance,
  playLottery
}: PlayLotteryProps) => {

  const [playAmount, setPlayAmount] = useState(tokenAllowance)
  // confirmation screen
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={150}>
        <Wrapper>
            <Section>
                <ContentHeader onDismiss={onDismiss}>Play lottery</ContentHeader>
                
                <Text bold fontSize="16px" color="textSubtle" mb="20px">
                    Deposit {tokenSymbol} to enter the lottery
                </Text>
                <Text bold fontSize="16px" color="textSubtle" mb="20px">
                    Balance: {tokenBalance} {tokenSymbol}
                </Text>
                <Text bold fontSize="16px" color="textSubtle" mb="20px">
                    Approved: {tokenAllowance} {tokenSymbol}
                </Text>
                <Input 
                    // placeholder={tokenBalance}
                    value={playAmount}
                    onChange={(e) => setPlayAmount(e.target.value)}
                    style={{ marginBottom: '20px', marginTop: '20px', }} 
                />
                <Button
                    onClick={() => playLottery(playAmount)}
                    disabled={
                        !parseFloat(playAmount) ||
                        new BigNumber(playAmount).lte(new BigNumber(0)) ||
                        new BigNumber(playAmount).gt(new BigNumber(tokenAllowance))
                    }
                >Play now</Button>
            </Section>
        </Wrapper>
    </Modal>
  )
}

export default PlayLotteryModal
