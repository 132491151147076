import React, { useState } from 'react'
import BigNumber from 'bignumber.js'
import { CardBody, Button, Text, Heading } from '@pancakeswap-libs/uikit'
import { Wrapper, BottomGrouping } from 'components/swap/styleds'
import { AutoColumn } from 'components/Column'
import { AutoRow } from 'components/Row'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useActiveWeb3React } from 'hooks'
import { useTokenContract, useTimestampedMasterChefContract } from 'hooks/useContract'
import { MASTER_CHEF_ADDRESS } from 'constants/contracts'
import ApproveTokenModal from './ApproveModal'
import StartFarmingModal from './StartFarming'
import ConfirmationModal from './ConfirmationModal'
import AppBody from '../../AppBody'
import type { PoolType } from '../type'

BigNumber.config({ EXPONENTIAL_AT: 1e+9 }) // never return exponential values

const Farm = (
    {pool, pendingReward, userInfo, tokenBalance, tokenAllowance}
    :
    {pool: PoolType, pendingReward: any, userInfo: any, tokenBalance: any, tokenAllowance: any}) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [pendingTxHash, setPendingTxHash] = useState('');
    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const [showStartFarmingModal, setShowStartFarmingModal] = useState(false);

    const { account } = useActiveWeb3React()
    let isApproved = false
    let stakedAmount = 0;
    let stakeTokenAccountBalance = '0';
    let accountTokenAllowance = '0';

    const tokenContract = useTokenContract(pool.stakeToken, true)
    const masterChef = useTimestampedMasterChefContract(true)

    const {
        pid,
        name,
        image,
        description,
        apr,
        // depositFee,
        withdrawFee,
        // rewardRate,
        // stakeToken,
        stakeTokenDecimals,
        stakeTokenSymbol,
        // rewardToken,
        rewardTokenDecimals,
        rewardTokenSymbol,
        // chainId
    } = pool

    if (pendingReward.valid && pendingReward.result) {
        const bigInt = new BigNumber(pendingReward.result[0].toString())
        const exponential = new BigNumber(10).exponentiatedBy(rewardTokenDecimals)
        const toNative = bigInt.dividedBy(exponential)
        pendingReward = toNative.toFixed(4)
    } else {
        pendingReward = 0
    }

    if (userInfo.valid && userInfo.result) {
        // eslint-disable-next-line dot-notation
        const bigAmount = userInfo.result['amount']
        const exponential = new BigNumber(10).exponentiatedBy(stakeTokenDecimals)
        const bigInt = new BigNumber(bigAmount.toString())
        const toNative = bigInt.dividedBy(exponential)
        stakedAmount = toNative.toNumber()
    }

    if (tokenBalance.valid && tokenBalance.result) {
        // JSBI writing decimals to zero, so we use BigNumber here
        // console.log(tokenBalance.result[0])
        const bigInt = new BigNumber(tokenBalance.result[0].toString())
        const exponential = new BigNumber(10).exponentiatedBy(stakeTokenDecimals)
        const toNative = bigInt.dividedBy(exponential)
        stakeTokenAccountBalance = toNative.toString()
    }
    
    if (tokenAllowance.valid && tokenAllowance.result) {
        const bigInt = new BigNumber(tokenAllowance.result[0].toString())
        const exponential = new BigNumber(10).exponentiatedBy(stakeTokenDecimals)
        const toNative = bigInt.dividedBy(exponential)
        accountTokenAllowance = toNative.toString()
        isApproved = bigInt.isGreaterThan(0)
    }

     const approveCallback = async (amount: string) => {
        try {
            setPendingTxHash('')
            setShowApprovalModal(false)
            setShowConfirmModal(true)
            if (!tokenContract) {
                return
            }
            const exponential = new BigNumber(10).exponentiatedBy(stakeTokenDecimals)
            const bigAmount = new BigNumber(amount).multipliedBy(exponential)
            const tx = await tokenContract.approve(MASTER_CHEF_ADDRESS, bigAmount.toString())
            setPendingTxHash(tx.hash);
            await tx.wait()
        } catch (e) {
            console.error(e)
        } finally {
            setShowConfirmModal(false)
            setPendingTxHash('')
        }
    }
    
    const startFarmingCallback = async (amount: string) => {
        try {
            setPendingTxHash('')
            setShowStartFarmingModal(false)
            setShowConfirmModal(true)
            if (!masterChef) {
                return
            }
            const exponential = new BigNumber(10).exponentiatedBy(stakeTokenDecimals)
            const bigAmount = new BigNumber(amount).multipliedBy(exponential)
            const tx = await masterChef.deposit(pid, bigAmount.toString())
            setPendingTxHash(tx.hash);
            await tx.wait()
        } catch (e) {
            console.error(e)
        } finally {
            setShowConfirmModal(false)
            setPendingTxHash('')
        }
    }
   
    const harvest = async () => {
        try {
            setPendingTxHash('')
            setShowConfirmModal(true)
            if (!masterChef) {
                return
            }
            const tx = await masterChef.withdraw(pid, '0')
            setPendingTxHash(tx.hash);
            await tx.wait()
        } catch (e) {
            console.error(e)
        } finally {
            setShowConfirmModal(false)
            setPendingTxHash('')
        }
    }
     
    const exitFarm = async () => {
        try {
            setPendingTxHash('')
            setShowConfirmModal(true)
            if (!masterChef) {
                return
            }
            // const exponential = new BigNumber(10).exponentiatedBy(stakeTokenDecimals)
            // eslint-disable-next-line dot-notation
            const bigAmount = new BigNumber(userInfo.result['amount'].toString())
            const tx = await masterChef.withdraw(pid, bigAmount.toString())
            setPendingTxHash(tx.hash);
            await tx.wait()
        } catch (e) {
            console.error(e)
        } finally {
            setShowConfirmModal(false)
            setPendingTxHash('')
        }
    }
   

    return (
        <AutoColumn style={{minWidth: "350px", margin: "30px"}}>
            <StartFarmingModal
                isOpen={showStartFarmingModal}
                onDismiss={() => setShowStartFarmingModal(false)}
                pool={pool}
                tokenBalance={stakeTokenAccountBalance}
                tokenAllowance={accountTokenAllowance}
                startFarmingCallback={startFarmingCallback}
            />
            <ApproveTokenModal 
                isOpen={showApprovalModal} 
                onDismiss={() => setShowApprovalModal(false)}
                tokenBalance={stakeTokenAccountBalance}
                approvalCallback={approveCallback}
                pool={pool}
             />
            <ConfirmationModal
                isOpen={showConfirmModal}
                onDismiss={() => setShowConfirmModal(false)}
                hash={pendingTxHash}
                content={() => (
                    <div style={{ padding: '2rem', textAlign: 'center' }}>
                        <Text fontSize="20px">Confirm this transaction in your wallet</Text>
                    </div>
                )}
                attemptingTxn={!pendingTxHash}
                pendingText="Pending Confirmation"
            />
            <AppBody>
                <Wrapper>              
                    <CardBody>
                        <AutoRow justify="space-between" my="10px">
                            <AutoColumn>
                                <Heading size="lg">
                                    {name}
                                </Heading>
                                <Text mb="15px" mt="5px" fontSize="14px" bold>{ description }</Text>
                            </AutoColumn>
                            <AutoColumn>
                                <img src={image} alt='' width={72} />
                            </AutoColumn>
                        </AutoRow>
                        
                        <hr />
                        <AutoRow justify="space-between" my="10px">
                            <AutoColumn>
                                <Text fontSize="16px">Apr:</Text>
                            </AutoColumn>
                            <AutoColumn>
                                <Text fontSize="16px">{ apr }%</Text>
                            </AutoColumn>
                        </AutoRow>

                        <AutoRow justify="space-between" my="10px">
                            <AutoColumn>
                                <Text fontSize="16px">Earn:</Text>
                            </AutoColumn>
                            <AutoColumn>
                                <Text fontSize="16px">{ stakeTokenSymbol }</Text>
                            </AutoColumn>
                        </AutoRow>

                        <AutoRow justify="space-between" my="10px">
                            <AutoColumn>
                                <Text fontSize="16px">Exit fee:</Text>
                            </AutoColumn>
                            <AutoColumn>
                                <Text fontSize="16px">{ withdrawFee }%</Text>
                            </AutoColumn>
                        </AutoRow>

            

                        <AutoRow justify="space-between" mt="15px">
                            <AutoColumn>
                                <Text fontSize="18px" bold>{ rewardTokenSymbol } Earned: </Text>
                            </AutoColumn>
                        </AutoRow>

                        <AutoRow justify="space-between" my="15px">
                            <AutoColumn>
                                <Text fontSize="18px" bold>{ pendingReward }</Text>
                            </AutoColumn>
                            <AutoColumn>
                                <Button
                                    onClick={harvest}
                                    disabled={stakedAmount <= 0 || showConfirmModal}
                                    style={{ width: '100%' }}
                                    variant='success'
                                    scale='sm'
                                >
                                    Harvest
                                </Button>
                            </AutoColumn>
                        </AutoRow>

                        <AutoRow justify="space-between" my="20px">
                            <AutoColumn>
                                <Text fontSize="16px">{ stakeTokenSymbol } Staked:</Text>
                            </AutoColumn>
                            <AutoColumn>
                                <Text fontSize="16px">{ stakedAmount.toFixed(2) } </Text>
                            </AutoColumn>
                        </AutoRow>

                        <BottomGrouping>
                        {!account ? (
                                <ConnectWalletButton width="100%" />
                            ) : stakedAmount > 0 ? (
                                <Button
                                    disabled={showConfirmModal}
                                    onClick={exitFarm}
                                    style={{ width: '100%' }}
                                    variant='danger'
                                >
                                    Exit Farm
                                </Button> 
                            ) : 
                            isApproved ? 
                            (
                                <Button
                                    onClick={() => setShowStartFarmingModal(true)}
                                    disabled={showStartFarmingModal || showConfirmModal}
                                    style={{ width: '100%' }}
                                    variant='primary'
                                >
                                    Farm { stakeTokenSymbol }
                                </Button>
                            ):
                            (
                                <Button
                                    onClick={() => setShowApprovalModal(true)}
                                    disabled={showApprovalModal || showConfirmModal}
                                    style={{ width: '100%' }}
                                    variant='primary'
                                >
                                    Approve { stakeTokenSymbol }
                                </Button>
                            )
                            }
                        </BottomGrouping>
                    </CardBody>
                </Wrapper>
            </AppBody>
        </AutoColumn>
    )
}

export default Farm