import React, { useState } from 'react'
import BigNumber from 'bignumber.js'
import { Button, Text, Input } from '@pancakeswap-libs/uikit'
import Modal from 'components/Modal'
// import { useActiveWeb3React } from 'hooks'
import { Wrapper, Section, ContentHeader } from './Helpers'
import type { PoolType } from '../type'

interface StartFarmingModalProps {
    isOpen: boolean
    onDismiss: () => void
    pool: PoolType
    tokenBalance: string
    tokenAllowance: string
    startFarmingCallback: (T: string) => void
}

const StartFarmingModal = ({
  isOpen,
  onDismiss,
  pool,
  tokenBalance,
  tokenAllowance,
  startFarmingCallback
}: StartFarmingModalProps) => {

  const [farmAmount, setFarmAmount] = useState(tokenAllowance)
  // confirmation screen
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={150}>
        <Wrapper>
            <Section>
                <ContentHeader onDismiss={onDismiss}>{pool.name} farm</ContentHeader>
                
                <Text bold fontSize="16px" color="textSubtle" mb="20px">
                    Deposit {pool.stakeTokenSymbol} to {pool.name} farm
                </Text>
                <Text bold fontSize="16px" color="textSubtle" mb="20px">
                    Balance: {tokenBalance} {pool.stakeTokenSymbol}
                </Text>
                <Text bold fontSize="16px" color="textSubtle" mb="20px">
                    Approved: {tokenAllowance} {pool.stakeTokenSymbol}
                </Text>
                <Input 
                    // placeholder={tokenBalance}
                    value={farmAmount}
                    onChange={(e) => setFarmAmount(e.target.value)}
                    style={{ marginBottom: '20px', marginTop: '20px', }} 
                />
                <Button
                    onClick={() => startFarmingCallback(farmAmount)}
                    disabled={
                        !parseFloat(farmAmount) ||
                        new BigNumber(farmAmount).lte(new BigNumber(0)) ||
                        new BigNumber(farmAmount).gt(new BigNumber(tokenAllowance))
                    }
                >Deposit {pool.stakeTokenSymbol}</Button>
            </Section>
        </Wrapper>
    </Modal>
  )
}

export default StartFarmingModal
