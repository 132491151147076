import React from 'react'
import { AutoRow } from 'components/Row'
import { useActiveWeb3React } from 'hooks'
import { /* useTokenContract, */ useTimestampedMasterChefContract } from 'hooks/useContract'
import { useMultipleContractSingleData, useSingleContractMultipleData } from 'state/multicall/hooks'
import { TIMESTAMPED_MASTER_CHEF_POOLS } from 'constants/farm'
import { MASTER_CHEF_ADDRESS } from 'constants/contracts'
import ERC20_INTERFACE from '../../constants/abis/erc20'
import StakeWrapper from './components/Farm'

const Stake = () => {
    let { account } = useActiveWeb3React()
    
    if (!account) {
        account = '0x0000000000000000000000000000000000000000';
    }
    const masterChef = useTimestampedMasterChefContract(true)
    // const tokenContract = useTokenContract()
    const pendingRewards = useSingleContractMultipleData(
        masterChef, 
        'pendingReward', 
        TIMESTAMPED_MASTER_CHEF_POOLS.map( pool => [pool.pid, account as string])
    )
    const userInfos = useSingleContractMultipleData(
        masterChef, 
        'userInfo', 
        TIMESTAMPED_MASTER_CHEF_POOLS.map( pool => [pool.pid, account as string])
    )

    const tokenBalances = useMultipleContractSingleData(
        TIMESTAMPED_MASTER_CHEF_POOLS.map( pool => pool.stakeToken),
        ERC20_INTERFACE,
        'balanceOf',
        [account as string]
    )

    const tokenAllowances = useMultipleContractSingleData(
        TIMESTAMPED_MASTER_CHEF_POOLS.map( pool => pool.stakeToken),
        ERC20_INTERFACE,
        'allowance',
        [account as string, MASTER_CHEF_ADDRESS]
    )
 
    return (
        
           <AutoRow justify="center" gap="md">
                {
                    TIMESTAMPED_MASTER_CHEF_POOLS
                    .map( (pool, index) => (
                        <StakeWrapper
                            key={pool.pid}
                            pool={pool}
                            pendingReward={pendingRewards[index]}
                            userInfo={userInfos[index]}
                            tokenBalance={tokenBalances[index]}
                            tokenAllowance={tokenAllowances[index]}
                        />
                    ))
                }
            </AutoRow>
    )
}

export default Stake