import React, { useState, useEffect } from 'react';

const Countdown = ({ epochSeconds }) => {
  function calculateTimeRemaining() {
    const currentTime = Math.floor(Date.now() / 1000);
    return epochSeconds - currentTime;
  }
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {timeRemaining <= 0 ? (
        <span>ended</span>
      ) : (
        <span>
          {Math.floor(timeRemaining / 3600)}h{' '}
          {Math.floor((timeRemaining % 3600) / 60)}m{' '}
          {timeRemaining % 60}s
        </span>
      )}
    </div>
  );
};

export default Countdown;
